import React, { memo, useCallback, useContext, useState, useEffect, useRef } from 'react';
import './calldisposition.css';
import { AgentContext } from '../AgentContextProvider';
import { ContactContext } from '../ContactContextProvider';
import { useConnected, useDestroy, useCallCompleted, contact } from '../../hooks.js';
import { instanceId } from '../../config.js';
import { apiDisposition } from '../../services/models/disposition.js';
import TextField from '@material-ui/core/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DispositionModal from './DispositionModal';
import DropDown from './DropDown';
import { useDispatch, useSelector } from 'react-redux'
import { ResetDisposition } from '../app/dispositionSlice';
import { sortByKey } from './utils';
import { Alert, Collapse, IconButton } from "@mui/material";
import { CloseOutlined } from '@material-ui/icons';

const CallDisposition = (props) => {
  const state = useContext(AgentContext);
  let contactstate = useContext(ContactContext);
  let onContact = contactstate.onContact;
  let primarycontactid = contactstate.primarycontactid;
  const isLoggedIn = state.isLoggedIn;
  const modalWidth = props.width;
  const modalHeight = props.height;
  const [customerDisposition, setCustomerDisposition] = useState(false);
  const [dispositions, setDispositions] = useState([]);
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const routingProfile = state.routingProfile;
  const idToken = state.idToken;
  const dispositionContext = useSelector(state => state.disposition)
  const dispatch = useDispatch()

  const dispositionSetRef = useRef(customerDisposition);
  const [shouldReset, setShouldReset] = useState(false)
  const [showAlert, SetShowAlert] = useState(false);
  const [alertSeverity, SetAlertSeverity] = useState("success");
  const [alertMessage, SetAlertMessage] = useState("");

  /**
   * Helper method to build an Alert Message
   * @param {*} message
   * @param {*} severity
   * @param {*} isVisible
   */
  const BuildAlertMessage = (message, severity, isVisible) => {
    SetAlertMessage(message);
    SetAlertSeverity(severity);
    SetShowAlert(isVisible);
  }


  //Creates reference to the status of the customer disposition (true = Disposition has been set || false = Disposition has not been set)
  useEffect(() => {
    dispositionSetRef.current = customerDisposition;
  }, [customerDisposition]);

  // Creates a reference to the selected dispositions in the Select Element
  useEffect(() => {
    checkSelections(dispositions)
  }, [dispositionContext, dispositions]);

  const checkSelections = (dispositions) => {
    let aKeys = dispositions.map((disposition, i) => disposition[0].name).sort()
    let bKeys = Object.keys(dispositionContext).sort()
    setReadyToSubmit(JSON.stringify(aKeys) === JSON.stringify(bKeys))
    }

  const setPrimaryID = (attrobj) => {
    let newObj = { ...contactstate, ...attrobj }
    delete newObj['setOnContact']
    let listArgs = Object.values(newObj).map(val => { return val })

    contactstate.setOnContact(...listArgs)
  }
  //Hook for behavior when Contact is connected
  const onConnected = useCallback(
    (c) => {
      try {
        dispatch(ResetDisposition())
        setCustomerDisposition(false)
        let currentContact = c.contactId;
        setPrimaryID({ primarycontactid: currentContact, onContact: true });
        setShouldReset(true)
      } catch (e) {
        console.error(e);
      }
    },
    []
  );
  useConnected(onConnected);

  const onCallCompleted = useCallback((c) => {
    if (dispositionSetRef.current == false) {
      setOpenModal(true);
    } else {
      c.clear();
    }
  }, []);
  useCallCompleted(onCallCompleted);

  const onDestroy = useCallback(() => {
    dispatch(ResetDisposition())
    setShouldReset(true)
    setOpenModal(false);
    setPrimaryID({ primarycontactid: '', onContact: false });
  }, []);
  useDestroy(onDestroy);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    try {
      getDispositions();
    } catch (e) {
      console.error(e);
    }
  }, [isLoggedIn]);

  // Loads the disposition list from the DDB table
  async function getDispositions() {
    apiDisposition
      .get({ routingProfile: routingProfile }, idToken)
      .then((res) => {
        let items = res;
        items.map((disposition) => { return disposition });
        setDispositions(items);
      }
      )
  }

  //Api that updates the Disposition in the Contact Attributes, attr
  async function updateDisposition(e) {
    e.preventDefault();

    try {
      if (onContact === true) {
        apiDisposition
          .put({
            routingProfile: routingProfile,
            instanceId: instanceId,
            contactId: primarycontactid,
            attributes: dispositionContext
          }, idToken)
          .then(res => {
            if (res.status == 200) {
            setCustomerDisposition(true);
              BuildAlertMessage('Success', 'success', true)
            // Check if the Modal is open (the agent didn't update the disposition before the contact ended)
            //If so, close the modal since the update was successful
            if (openModal == true) {
              setOpenModal(false);
              contact.clear();
            }
            return res;
            } else {
             console.error(res)
            BuildAlertMessage('Request Failed', 'error', true)
            }
          })
      } else {
        dispatch(ResetDisposition())
        setReadyToSubmit(false);
        setShouldReset(true)
        BuildAlertMessage('No contact found.', 'error', true)
      }
    } catch (e) {
      BuildAlertMessage(e, 'error', true)
      console.error(e);
    }
  }
  return (
    <div >
      <Box>
        <form onSubmit={updateDisposition} id="Disposition Form">
          {showAlert &&
            <Collapse in={true}>
              <Alert severity={alertSeverity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => SetShowAlert(false)}>
                    <CloseOutlined />
                  </IconButton>}>
                {alertMessage}
              </Alert>
            </Collapse>}
          {dispositions ? (
            <>
              <TextField
                style={{ marginRight: '5px', marginBottom: '10px' }}
                disabled={true}
                InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                className="not_draggable"
                value={contactstate.primarycontactid}
                id="contactid"
                label="Current Contact"
                fullWidth
              />

              {dispositions.map((disposition) => {
                let parentKey = disposition[0]['name']
                disposition[0]['subcategories'] = sortByKey(disposition[0]['subcategories'], 'asc', 'priority')

                return (
                  <div key={parentKey + 'disposition-disposition'}>
                    <DropDown disposition={disposition} shouldReset={shouldReset} setShouldReset={setShouldReset} />
                  </div>)
              }
              )
              }

              <Button type="submit" variant="contained" disabled={!readyToSubmit}>
                Submit
              </Button>

              {openModal && (
                <DispositionModal
                  InputLabelProps={{ shrink: true, style: { color: '#000000', fontSize: 18 } }}
                  modalWidth={modalWidth}
                  modalHeight={modalHeight}
                  updateDisposition={updateDisposition}
                  dispositions={dispositions}
                  openModal={openModal}
                  shouldReset={shouldReset}
                  setShouldReset={setShouldReset}
                  readyToSubmit={readyToSubmit}
                />
              )}
            </>
          ) : (
            <div>Loading...</div>
          )}
        </form>
      </Box>
    </div>
  );
};
export default memo(CallDisposition);
