import React, { useContext } from 'react';

import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import Paper from '@mui/material/Paper';
import { ContactContext } from '../ContactContextProvider';
import Button from '@mui/material/Button';
import DropDown from './DropDown';
import './calldisposition.css';

export default function DispositionModal(props) {
  const [setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  let contactstate = useContext(ContactContext);
  const { updateDisposition, dispositions, modalHeight, modalWidth, openModal, readyToSubmit, setShouldReset, shouldReset } = props;

  const style = {
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
      margin: '5px',
      backgroundColor: 'red',
      fontWeight: 'bold',
      borderRadius: '5px'
    },
    body: {
      padding: 5,
      height: modalHeight,
      width: modalWidth,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4
    }
  };

  let primarycontactid = contactstate.primarycontactid;

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form onSubmit={updateDisposition}>
          <Paper style={style.body}>
            <div style={style.header}>Please submit disposition to continue</div>
            <>
              <TextField
                style={{ marginRight: '5px', marginBottom: '10px' }}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                className="not_draggable"
                value={primarycontactid}
                id="contactid"
                label="Current Contact"
                fullWidth
              />

              {dispositions.map((disposition) => {
                return (
                  <div key={'-disposition-dev'}>
                    <DropDown
                    disposition={disposition}
                    shouldReset={shouldReset}
                    setShouldReset={setShouldReset}
                     />
                  </div>)
              })}

              <Button type="submit" variant="contained" disabled={!readyToSubmit}>
                Submit
              </Button>
            </>
          </Paper>
        </form>
      </Modal>
    </div>
  );
}
