import React, { useMemo, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { AgentContextProvider } from './components/AgentContextProvider';
import { ContactContextProvider } from './components/ContactContextProvider';
import Login from "./components/Login/Login";
import { clientId, auth_url, redirectUri, signInUrl } from './config.js';
import { auth } from './services/models/auth.js'

export let token;
const App = () => {
  const Dash = useMemo(() => Dashboard, []);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [idToken, setIdToken] = useState('');
  let search = window.location.search;
  let codeparam = new URLSearchParams(search);
  let code = codeparam.get('code');
  window.history.replaceState({}, document.title, "/")

 function login() {
   if (code === null || code == 'undefined') {
     window.location.href = signInUrl
   } else {
      auth.authpost({ grant_type: 'authorization_code', client_id: clientId, code: code, redirect_uri: redirectUri })
       .then((res) => {
         setIdToken(res.data.id_token)
       });
   }
  }

   useEffect(() => {
   login()
  }, [])

  useEffect(() => {
   setIsSignedIn(true);
   token = idToken
  }, [idToken])

  return (
    <div className='mainApp'>
      <Router>
        <Switch>
          <AgentContextProvider>
            <ContactContextProvider>
              {(isSignedIn === true) ? <Route exact path="/" render={props => <Dashboard idToken={idToken} />} /> : <Login />}
            </ContactContextProvider>
          </AgentContextProvider>
        </Switch>
      </Router>
    </div>

  );
};


export default App;
