
import React, { memo, useState, useEffect, useContext, useRef } from "react";
import { AgentContext } from './AgentContextProvider';
import '../css/queue.css'
import { instanceId, slaTime, tz, apireq } from '../config.js'
import MainTable from "./common/MainTable";

import { apiQueueMetrics } from '../services/models/queuemetrics.js';


const Queue = (props) => {

  const [queuemetrics, setQueueMetrics] = useState(null);
  const [queueColumns, setQueueColumns] = useState([])
  const [queueRows, setQueueRows] = useState([])
  const state = useContext(AgentContext)
  const queues = state.queues
  const isLoggedIn = state.isLoggedIn
  const channels = state.channels
  const queueRef = useRef()
  const idToken = state.idToken

  function setRows(items) {
    let rows = []
    for (let i in items) {
      let row = {}
      row['Queue'] = queues.filter((item) => item.queueARN.includes(items[i].Id)).map(item => item.name)
      let metrics = items[i]['Metrics']
      for(let metric in metrics ){
          let obj = Object.entries(metrics[metric])
        row[obj[0][0]] = formatTime([obj[0][0], obj[0][1]])
      }
      rows.push(row)
    }
    return rows
  }

  function setColumns(items) {
    let columns = [{ id: 'Queue', label: 'Queue', minWidth: 170 }]
    const headers = items[0].Metrics.flatMap(Object.keys);
    for (let header in headers) {
      columns.push({ id: headers[header], label: headers[header] })
    }
    return columns
    }

  function getIdFromArn(arn){
    let queue_pattern = new RegExp('arn:aws:connect:([\\w|\\-]+):(\\w+):instance/([\\w|\\-]+)/queue/([\\w|\\-]+)');
    const groups = queue_pattern.exec(arn);
    return groups[4]
  }

  async function getMetrics() {
    let queuearns = queues.map((item) => { return getIdFromArn(item.queueARN) })

    let arr = queuearns

    apiQueueMetrics.put({ queues: arr })
      .then((res) => {
      const items = res.map((i) => { return i })
      const obj = { val: items, timestamp: new Date().getTime() }
      const metrics = localStorage.setItem("QueueMetrics", JSON.stringify(obj))

      setQueueMetrics(JSON.parse(localStorage.getItem('QueueMetrics')).val)
      setQueueColumns(setColumns(items))
      setQueueRows(setRows(items))
      });
  }

  function formatTime(val) {
    if (val[0] === 'SLA') {
      if (val[1] === 0) {
        return 'null'
      } else {
        return val[1] + '%'
      }

    } else if (val[0] === 'Oldest Contact Age' || val[0] === 'Answer Time(AVG)') {
      let s = val[1]
      return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s

    } else {
      return JSON.parse(val[1])
    }
  }


  useEffect(() => {
    //Fires every 20 seconds

    if (!isLoggedIn) return
    const intervalId = setInterval(() => {
      const storagetime = JSON.parse(localStorage.getItem('QueueMetrics')).timestamp
      const timenow = new Date().getTime()
      const passedtime = timenow - storagetime

      if ((!storagetime) || (passedtime > 5000)) {
        getMetrics()
      }
    }, 5000)

    return () => { clearInterval(intervalId); }
  }, [queuemetrics])

  useEffect(() => {
    //Only fires when the site initially renders
    if (!isLoggedIn) return
    const timenow = new Date().getTime()
    if (localStorage.getItem('QueueMetrics') === null || localStorage.getItem('QueueMetrics').value === '[]') {
      getMetrics()
    } else {
      if ((timenow - JSON.parse(localStorage.getItem('QueueMetrics')).timestamp) > 5000) {
        getMetrics()
      } else {
        setQueueMetrics(JSON.parse(localStorage.getItem('QueueMetrics')).val)
      }
    }
  }, [])


  return (
    <>
      {queuemetrics ?
        <MainTable columns={queueColumns} rows={queueRows} />

        : <div>Loading...</div>}
    </>
  );

};

export default memo(Queue);
